import { render, staticRenderFns } from "./Tenants.vue?vue&type=template&id=51c845bb&scoped=true&"
import script from "./Tenants.vue?vue&type=script&lang=js&"
export * from "./Tenants.vue?vue&type=script&lang=js&"
import style0 from "./Tenants.vue?vue&type=style&index=0&id=51c845bb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51c845bb",
  null
  
)

export default component.exports